#rsvp-container {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

#rsvp-container .loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#rsvp-container .loading svg {
  height: 120px;
  width: 120px;
}

#rsvp-container .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}
#rsvp-container .top.responded {
  margin-bottom: 0;
  align-items: center;
}
#rsvp-container .top.responded h1 {
  font-size: 1.4rem;
}

#rsvp-container .top h1 {
  margin: 0;
  font-size: 1.8rem;
}

.code-details {
  font-size: 1rem;
  color: #929292;
}

button.dietary-opener {
  background: none;
  border: none;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: left;
  cursor: pointer;
  color: #707070;
  font-size: 0.7rem;
  margin: 0.3rem 0 0 0;
  padding: 0;
}

.dietary-restrictions {
  display: flex;
  flex-direction: column;
}

.dietary-restrictions label {
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #707070;
}
.dietary-restrictions input {
  font-size: 1rem;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #707070;
  padding: 6px 4px;
}
span.additional-info {
  font-size: 0.94rem;
  font-style: italic;
  /* color: #707070; */
}

.guest {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media only screen and (max-width:600px) {
  .rsvp-heading {
    flex-direction: column;
  }

  .guest {
    flex-direction: column;
    margin-bottom:2rem;
  }
  .attendance {
    display: none;
  }
  form .guest .right .top {
    height: 0;
  }
}

.guest .left {
  display: flex;
  flex-direction: column;
}

.guest .left .guest-name {
  margin: 1.1rem 0 0;
  height: 1.6rem;
  font-size: 1.2rem;
}

.guest .right {
  display: grid;
  grid-template-areas: "top top" "saturday sunday" "rehearsal rehearsal";
  margin-right: 1rem;
}

.guest .right .top {
  grid-area: top;
  height: 1.6rem;
}

.guest .right label.attendance {
  font-family: annabelle-jf, sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.guest .right .saturday {
  grid-area: saturday;
}

.guest .right .sunday {
  grid-area: sunday;
  margin-left: 1.4rem;
  margin-bottom: 0.6rem;
}

.guest .right .rehearsal {
  grid-area: rehearsal;
}

.bottom {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.bottom button, .top button {
  display: inline-block;
  font-family: annabelle-jf, sans-serif;
  border: none;
  text-decoration: none;
  color: #000;
  font-size: 1.4rem;
  padding: 3px 16px 3px 13px;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 -3px 0 0 #1d5b3d;
  border: 3px solid #1d5b3d;
  background-position: center center;
  background-size: cover;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 6px;
  padding-top: 4px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container.disabled {
  cursor: not-allowed;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #000;
}
.checkbox-container.disabled .checkmark {
  background-color: #ccc;
}
.checkmark:focus {
  border-style: dashed;
}
.kids-meal {
  margin-top: 0.4rem;
  font-size: 0.8rem;
  padding-left: 34px;
}
.kids-meal .checkmark {
  height: 22px;
  width: 22px;
  overflow:hidden;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark,
.checkbox-container:focus input ~ .checkmark,
.checkbox-container input:focus ~ .checkmark {
  border-style: dashed;
}
.checkbox-container.disabled:hover input ~ .checkmark,
.checkbox-container.disabled:focus input ~ .checkmark,
.checkbox-container.disabled input:focus ~ .checkmark {
  background-color: #ccc;
  border-style:solid;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  width: 100%;
  height: 100%;
  background-image: url("/img/ring_checkmark.png");
  background-size: 83% 83%;
  background-position: center center;
  background-repeat: no-repeat;
}
.sunday .checkbox-container .checkmark:after {
  background-image: url("/img/party_checkmark.png");
}
.rehearsal .checkbox-container .checkmark:after {
  background-image: url("/img/cheers.png");
}
.not-attending .checkbox-container .checkmark:after {
  background-image: url("/img/unable.png");
}
.kids-meal.checkbox-container .checkmark:after {
  background-image: none;
  transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  margin: 2px 0 0 7px;
}


.view-rsvp {
  margin-top: 1.2rem;
}

.view-rsvp ul {
  margin-top: 0.1rem;
}
