@import url(https://use.typekit.net/vts1boo.css);
html {
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: mrs-eaves, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  -webkit-font-feature-settings: "lnum";
          font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}
#root {
  height: 100%;
  overflow:hidden;
}


/** Typography
 *
 */

h1, h2.big, .callout {
  font-family: annabelle-jf, sans-serif;
  font-weight: normal;
}

h2, h3, h4, h5, a, span {
  font-family: mrs-eaves, sans-serif;
  font-weight: normal;
  -webkit-font-feature-settings: "lnum";
          font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}

p, a.normal {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  line-height: 1.33rem;
  -webkit-font-feature-settings: "lnum";
          font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}

h1 {
  font-size: 2.25rem;
  line-height: 3rem;
  margin: 2.2rem 0 0.4rem;
}

h2:first-of-type {
  margin-top: 1.2rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 2.6rem 0 0.3rem;
}

h3 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h4 {
  font-size: 0.9rem;
  text-transform: uppercase;
}

a:hover {
  text-decoration: underline;
}


@media only screen and (max-width: 700px) {
  html {
    font-size:18px;
  }
}


/** Layout **/
.content-container {
  width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width:860px) {
  .content-container {
    width: 84%;
  }
}


@media only screen and (max-width:600px) {
  .content-container {
    width: 90%;
  }
}


.day {
  margin-bottom: 3.7rem;
}
#total-container {
  position: relative;
  max-height: 100vh;
}
#splashcontainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url('/img/TopBG.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
}

#splashcontent {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  flex-grow: 1;
  color: white;
}

#splashbuttons {
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
}

#splashbuttons>button {
  flex-grow: 1;
  margin: 2rem;
  display: inline-block;
  border: none;
  text-decoration: none;
  color: #000;
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
  -webkit-transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease, -webkit-transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border-radius: 0.6rem;
  box-shadow: 0 -0.5rem 0 0 #8E0627;
  background-position: center center;
  background-size: cover;
}

#splashbuttons>button>a {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  font-family: annabelle-jf, sans-serif;
  color: black;
  padding: 1.4rem 0;
}

#splashbuttons>button.save-the-date {background-image: url('/img/save-the-date-button.jpg');}
#splashbuttons>button.the-big-day {background-image: url('/img/the-big-day-button.jpg');}


h1.title { 
  font-size: 4.6rem; 
  font-weight: normal;
}

h1 > span {
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 1.2rem;
  display: block;
}

@media only screen and (max-width:600px) {
  #splashbuttons {
    flex-direction: column;
  }
  #splashbuttons > button {
    margin: 1rem;
    margin-top: 0;
  }

  h1.title {
    font-size: 3rem;
  }
}
#save-the-date {
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
  background-image: url('/img/maptest.jpg');
  background-size: cover;
  background-color: rgba(255,255,255,0.77);
}

#save-the-date.hidden {
  -webkit-transform: translateY(110%);
          transform: translateY(110%);
}

#save-the-date .content-container {
  position: relative;
}

a.close {
  position: absolute;
  bottom: calc(100% + 0.7rem);
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 3px 1.7rem;
  text-decoration: none;
  color: black;
}
a.close:hover, a.close:focus {
  text-decoration: underline;
}

#save-the-date .content-container .content {
  margin: 0.7rem 0;
  padding-bottom: 2rem;
  height: calc(88vh - 2rem);
  overflow:scroll;
}

.registry-container {
  padding: 1.5rem 0 3rem 0;
  width: 96%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10%;
  align-items: center;
}

@media only screen and (max-width:540px) {
  .registry-container {
    grid-template-columns: 1fr;
    width: 65%;
    grid-gap: 2rem;
  }
}

.registry-container > a > img {
  width: 100%;
  height: auto;
  max-width: 220px;
}
#the-big-day-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
  background-image: url("/img/science_museum_big3.jpg");
  background-size: cover;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.77);
}

#the-big-day-wrapper.hidden {
  -webkit-transform: translateY(110%);
          transform: translateY(110%);
}

#the-big-day-wrapper .content-container {
  position: relative;
}

a.close {
  position: absolute;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 3px 1.7rem;
  text-decoration: none;
  color: black;
}
a.close:hover,
a.close:focus {
  text-decoration: underline;
}

#the-big-day-wrapper .content {
  margin: 0.7rem 0;
  padding-bottom: 2rem;
  height: calc(88vh - 2rem);
  overflow: scroll;
}

#the-big-day-wrapper .content .loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#the-big-day-wrapper .content .loading svg {
  height: 120px;
  width: 120px;
}
.registry-container {
  padding: 1.5rem 0 3rem 0;
  width: 96%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10%;
  align-items: center;
}

@media only screen and (max-width: 540px) {
  .registry-container {
    grid-template-columns: 1fr;
    width: 65%;
    grid-gap: 2rem;
  }
}

.registry-container > a > img {
  width: 100%;
  height: auto;
  max-width: 220px;
}


.row-w-meta {
  position: relative;
  margin-bottom: 1.6rem;
  margin-top: 0.4rem;
  min-height: 1.6rem; 
}

.row-w-meta p {
  margin: 0 0 0.25rem;
}

.row-w-meta .meta {
  position: absolute;
  right: 100%;
  top: 0;
  padding-right: 1rem;
  color: #444444;
  font-weight: 400;
  text-align: right;
  line-height: 1rem;
}
@media only screen and (max-width:860px) {
  .row-w-meta .meta {
    position: relative;
    text-align: left;
    margin-bottom: 4px;
    left: 0;
  }
}
.row-w-meta .meta span {
  font-size: 0.9rem;
  line-height: 1.33rem;
}
.row-w-meta .meta span.big {
  font-size: 3.5rem;
  line-height: 3.2rem;
}

.row-w-meta a {
  color: black;
  font-style: italic;
  margin-right: 1.4rem;
  font-size: 1.1rem;
}
.row-w-meta a.inline {
  margin-right: 0;
  font-style: normal;
}

.row-w-meta h2 {
  margin: 0 0 1rem;
  line-height: 1.2rem;
}
.row-w-meta.empty {margin-bottom: 0}

#entertainment {
  padding-bottom: 35vh;
}

#entertainment ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 0.3rem;
}

#entertainment ul a {
  text-decoration: none;
  color: black;
}
#entertainment ul a:hover {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}
#entertainment ul a.more {
  font-style: italic;
}

.blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.blocks h2 {
  margin-top: 0;
}

#accommodations ul {
  list-style-type: none;
  padding-left: 1.1rem;
}

#accommodations ul li {
  margin-bottom: 1.4rem;
  font-size: 1.12rem;
}

#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18vh;
}

#rsvp-input {
  background: none;
  border: none;
  border-bottom: 1px solid black;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
  max-width: 80vw;
}
#rsvp-input::-webkit-input-placeholder {
  font-size: 1.3rem;
  font-family: mrs-eaves, sans-serif;
}
#rsvp-input::-moz-placeholder {
  font-size: 1.3rem;
  font-family: mrs-eaves, sans-serif;
}
#rsvp-input:-ms-input-placeholder {
  font-size: 1.3rem;
  font-family: mrs-eaves, sans-serif;
}
#rsvp-input::-ms-input-placeholder {
  font-size: 1.3rem;
  font-family: mrs-eaves, sans-serif;
}
#rsvp-input::placeholder {
  font-size: 1.3rem;
  font-family: mrs-eaves, sans-serif;
}

#login-button {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 1.5rem;
  font-family: annabelle-jf, sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 1rem;
  padding: 6px 7px;
}
#login-button:focus {
  outline: none;
  border: 2px dotted black;
  padding: 4px 5px;

}

#rsvp-container {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

#rsvp-container .loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#rsvp-container .loading svg {
  height: 120px;
  width: 120px;
}

#rsvp-container .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}
#rsvp-container .top.responded {
  margin-bottom: 0;
  align-items: center;
}
#rsvp-container .top.responded h1 {
  font-size: 1.4rem;
}

#rsvp-container .top h1 {
  margin: 0;
  font-size: 1.8rem;
}

.code-details {
  font-size: 1rem;
  color: #929292;
}

button.dietary-opener {
  background: none;
  border: none;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: left;
  cursor: pointer;
  color: #707070;
  font-size: 0.7rem;
  margin: 0.3rem 0 0 0;
  padding: 0;
}

.dietary-restrictions {
  display: flex;
  flex-direction: column;
}

.dietary-restrictions label {
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #707070;
}
.dietary-restrictions input {
  font-size: 1rem;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #707070;
  padding: 6px 4px;
}
span.additional-info {
  font-size: 0.94rem;
  font-style: italic;
  /* color: #707070; */
}

.guest {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media only screen and (max-width:600px) {
  .rsvp-heading {
    flex-direction: column;
  }

  .guest {
    flex-direction: column;
    margin-bottom:2rem;
  }
  .attendance {
    display: none;
  }
  form .guest .right .top {
    height: 0;
  }
}

.guest .left {
  display: flex;
  flex-direction: column;
}

.guest .left .guest-name {
  margin: 1.1rem 0 0;
  height: 1.6rem;
  font-size: 1.2rem;
}

.guest .right {
  display: grid;
  grid-template-areas: "top top" "saturday sunday" "rehearsal rehearsal";
  margin-right: 1rem;
}

.guest .right .top {
  grid-area: top;
  height: 1.6rem;
}

.guest .right label.attendance {
  font-family: annabelle-jf, sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.guest .right .saturday {
  grid-area: saturday;
}

.guest .right .sunday {
  grid-area: sunday;
  margin-left: 1.4rem;
  margin-bottom: 0.6rem;
}

.guest .right .rehearsal {
  grid-area: rehearsal;
}

.bottom {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.bottom button, .top button {
  display: inline-block;
  font-family: annabelle-jf, sans-serif;
  border: none;
  text-decoration: none;
  color: #000;
  font-size: 1.4rem;
  padding: 3px 16px 3px 13px;
  cursor: pointer;
  text-align: center;
  -webkit-transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease, -webkit-transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 -3px 0 0 #1d5b3d;
  border: 3px solid #1d5b3d;
  background-position: center center;
  background-size: cover;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 6px;
  padding-top: 4px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container.disabled {
  cursor: not-allowed;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #000;
}
.checkbox-container.disabled .checkmark {
  background-color: #ccc;
}
.checkmark:focus {
  border-style: dashed;
}
.kids-meal {
  margin-top: 0.4rem;
  font-size: 0.8rem;
  padding-left: 34px;
}
.kids-meal .checkmark {
  height: 22px;
  width: 22px;
  overflow:hidden;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark,
.checkbox-container:focus input ~ .checkmark,
.checkbox-container input:focus ~ .checkmark {
  border-style: dashed;
}
.checkbox-container.disabled:hover input ~ .checkmark,
.checkbox-container.disabled:focus input ~ .checkmark,
.checkbox-container.disabled input:focus ~ .checkmark {
  background-color: #ccc;
  border-style:solid;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  width: 100%;
  height: 100%;
  background-image: url("/img/ring_checkmark.png");
  background-size: 83% 83%;
  background-position: center center;
  background-repeat: no-repeat;
}
.sunday .checkbox-container .checkmark:after {
  background-image: url("/img/party_checkmark.png");
}
.rehearsal .checkbox-container .checkmark:after {
  background-image: url("/img/cheers.png");
}
.not-attending .checkbox-container .checkmark:after {
  background-image: url("/img/unable.png");
}
.kids-meal.checkbox-container .checkmark:after {
  background-image: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  margin: 2px 0 0 7px;
}


.view-rsvp {
  margin-top: 1.2rem;
}

.view-rsvp ul {
  margin-top: 0.1rem;
}

