#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18vh;
}

#rsvp-input {
  background: none;
  border: none;
  border-bottom: 1px solid black;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
  max-width: 80vw;
}
#rsvp-input::placeholder {
  font-size: 1.3rem;
  font-family: mrs-eaves, sans-serif;
}

#login-button {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 1.5rem;
  font-family: annabelle-jf, sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 1rem;
  padding: 6px 7px;
}
#login-button:focus {
  outline: none;
  border: 2px dotted black;
  padding: 4px 5px;

}
