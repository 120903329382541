#the-big-day-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(0);
  transition: 500ms ease-in-out;
  background-image: url("/img/science_museum_big3.jpg");
  background-size: cover;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.77);
}

#the-big-day-wrapper.hidden {
  transform: translateY(110%);
}

#the-big-day-wrapper .content-container {
  position: relative;
}

a.close {
  position: absolute;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 3px 1.7rem;
  text-decoration: none;
  color: black;
}
a.close:hover,
a.close:focus {
  text-decoration: underline;
}

#the-big-day-wrapper .content {
  margin: 0.7rem 0;
  padding-bottom: 2rem;
  height: calc(88vh - 2rem);
  overflow: scroll;
}

#the-big-day-wrapper .content .loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#the-big-day-wrapper .content .loading svg {
  height: 120px;
  width: 120px;
}
.registry-container {
  padding: 1.5rem 0 3rem 0;
  width: 96%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10%;
  align-items: center;
}

@media only screen and (max-width: 540px) {
  .registry-container {
    grid-template-columns: 1fr;
    width: 65%;
    grid-gap: 2rem;
  }
}

.registry-container > a > img {
  width: 100%;
  height: auto;
  max-width: 220px;
}


.row-w-meta {
  position: relative;
  margin-bottom: 1.6rem;
  margin-top: 0.4rem;
  min-height: 1.6rem; 
}

.row-w-meta p {
  margin: 0 0 0.25rem;
}

.row-w-meta .meta {
  position: absolute;
  right: 100%;
  top: 0;
  padding-right: 1rem;
  color: #444444;
  font-weight: 400;
  text-align: right;
  line-height: 1rem;
}
@media only screen and (max-width:860px) {
  .row-w-meta .meta {
    position: relative;
    text-align: left;
    margin-bottom: 4px;
    left: 0;
  }
}
.row-w-meta .meta span {
  font-size: 0.9rem;
  line-height: 1.33rem;
}
.row-w-meta .meta span.big {
  font-size: 3.5rem;
  line-height: 3.2rem;
}

.row-w-meta a {
  color: black;
  font-style: italic;
  margin-right: 1.4rem;
  font-size: 1.1rem;
}
.row-w-meta a.inline {
  margin-right: 0;
  font-style: normal;
}

.row-w-meta h2 {
  margin: 0 0 1rem;
  line-height: 1.2rem;
}
.row-w-meta.empty {margin-bottom: 0}

#entertainment {
  padding-bottom: 35vh;
}

#entertainment ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 0.3rem;
}

#entertainment ul a {
  text-decoration: none;
  color: black;
}
#entertainment ul a:hover {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}
#entertainment ul a.more {
  font-style: italic;
}

.blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.blocks h2 {
  margin-top: 0;
}

#accommodations ul {
  list-style-type: none;
  padding-left: 1.1rem;
}

#accommodations ul li {
  margin-bottom: 1.4rem;
  font-size: 1.12rem;
}
